import Flex, { FlexProps } from '@/components/ui/Flex';
import Txt from '@/components/ui/Txt';
import { isArray, isString } from 'lodash';
import React from 'react';
import Box from '../ui/Box';

export type MetaValue = { label: string; value: React.ReactNode | string[] } | undefined;

export type MetaValues = MetaValue[];

export type EntryMetaProps = FlexProps<
  'div',
  {
    slotAfter?: React.ReactNode;
    metaValues: MetaValues;
  }
>;

const EntryMeta = ({ metaValues, slotAfter, cx, ...props }: EntryMetaProps) => {
  return (
    <Flex
      rounded
      colorSet="primaryTintLighter"
      paper
      bordered
      spacing="lg"
      alignItems="start"
      cx={{ p: 'md', mB: 'xl', ...cx }}
      {...props}>
      {metaValues.map((metaValue) => {
        const { label, value } = metaValue ?? {};
        if (!value || !label) return null;

        return !value ? null : (
          <Box cx={{ width: 'full' }} key={label}>
            <Txt variant="tiny" as="p" uppercase monoFont>
              {`${label}:`}
            </Txt>
            {(isArray(value) ? value : [value]).map((v, i) =>
              v ? (
                <React.Fragment key={i}>
                  {isString(v) ? (
                    <Txt variant="body" cx={{ mT: 'none', mB: 'none' }}>
                      {v}
                    </Txt>
                  ) : (
                    v
                  )}
                </React.Fragment>
              ) : null
            )}
          </Box>
        );
      })}
      {slotAfter}
    </Flex>
  );
};

export default EntryMeta;
