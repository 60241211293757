'use client';

import Carousel from '@/components/Carousel';
import EntryCard from '@/components/EntryCards';
import { PossibleEntryFragments } from '@/lib/parsers/entries';
import { maybeGet } from '@liquorice/utils';
import { useTranslations } from 'next-intl';
import BlockHeader from '../BlockHeader';
import Container from '../ui/Container';
import Grid from '../ui/Grid';

const RelatedEntries = (props: PossibleEntryFragments) => {
  const t = useTranslations('entryIndex');

  const relatedEvents = maybeGet(props, 'eventMultiple');
  const relatedArticles = maybeGet(props, 'articleMultiple');

  if (!relatedEvents && !relatedArticles) return;

  const entries = [];
  let title = '';

  if (relatedEvents && !relatedArticles.length) title = t('relatedEvents');
  if (!relatedEvents.length && relatedArticles) title = t('relatedArticles');
  if (relatedEvents.length && relatedArticles.length) title = t('related');

  if (!entries.length) return null;

  return (
    <Container as="section" cx={{ mY: '5xl' }}>
      <Grid>
        <Grid.Col offsetMd={2} md={8}>
          <BlockHeader
            heading={title}
            HeadingProps={{ color: 'primary', cx: { mB: 'xl' }, autoMargin: false }}
          />
          <Carousel
            slidesPerView={2}
            slidesPerGroup={2}
            spaceBetween={24}
            items={[]?.map((v, i) => (
              <EntryCard key={i} data={v} />
            ))}
          />
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default RelatedEntries;
